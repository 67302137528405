import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";

import {
  Box,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  Pagination,
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { findIndex } from "lodash";

import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import CatalogSectionAd from "../../components/Catalog/CatalogSectionAd";
import CatalogSectionContainer from "../../components/Catalog/CatalogSectionContainer";
import CatalogSectionMenu from "../../components/Catalog/CatalogSectionMenu";
import CatalogSectionProduct from "../../components/Catalog/CatalogSectionProduct";
import PageBox from '../../components/PageBox';
import SEO from '../../components/SEO';

Catalog.propTypes = {
  id: PropTypes.string,
};

Catalog.defaultProps = {
  id: '',
}

export default function Catalog({ id }) {
  const style = {
    wrapperPage: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: { xs: 'wrap', sm: 'wrap', lg: 'nowrap' },

      '& .infBlock': {
        display: {
          xs: 'block',
          sm: 'block',
          lg: 'none',
        }
      },
    },
    sideBar: {
      width: '100%',
      maxWidth: { sm: '100%', lg: '300px' },
      paddingRight: { sm: '0', lg: '31px' },

      '& > .infBlock': {
        display: { xs: 'none', sm: 'none', lg: 'block' }
      }
    },
    content: {
      width: {
        xs: '100%',
        sm: '100%',
        lg: 'calc(100% - 300px)'
      },
      marginBottom: {
        lg: '46px',
      }
    },
    wrapperChangeView: {
      color: '#6E7884',
      padding: '0',
      display: { xs: 'none', sm: 'none', lg: 'flex' },
      width: '60px',
      justifyContent: 'space-between',
    },
    wrapperPseudoButton: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '24px',
      height: '24px',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      cursor: 'pointer',

      '&.active > div': {
        border: '2px solid #FF780F',
      }
    },
    buttonTable: {
      border: '2px solid #B6BBC2',
      width: '18px',
      height: '7px',
      borderRadius: '2px',
      margin: '1px',
    },
    buttonCard: {
      border: '2px solid #B6BBC2',
      width: '8px',
      height: '7px',
      borderRadius: '2px',
      margin: '1px',
    },
    titleSorting: {
      color: '#6E7884',
      fontSize: '16px',
      margin: 0,
      display: {
        xs: 'none',
        sm: 'block',
      }
    },
    valueSorting: {
      color: '#0D1D32',
      margin: 0,

      '& > span': {
        fontWeight: 500,
        marginLeft: '6px',
      }
    },
    pagin: {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '24px',

      '& .Mui-selected': {
        backgroundColor: '#FF780F !important',
        color: '#ffffff',

        '&:hover': {
          background: '#ec6608 !important',
          color: '#ffffff',
        }
      }
    }
  }

  const LIMIT_PAGE = 12;
  const [page, setPage] = useState(1)

  const changePage = (_, value) => {
    setPage(value)
  }

  const [viewPage, setViewPage] = useState('table');

  const theme = useTheme();

  const matchesSM = useMediaQuery(theme.breakpoints.only("sm"));
  const matchesXS = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    if (matchesSM || matchesXS) {
      setViewPage('table')
    }
  }, [matchesSM, matchesXS]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const options = [
    { title: "вначале дороже", order: 'DESC', field: 'price' },
    { title: "вначале дешевле", order: 'ASC', field: 'price' },
    { title: "новые", order: 'ASC', field: 'operatedSince' },
  ];

  const [loader, setLoader] = useState(true);

  const [query, setQuery] = useState(`
    query {
      products (
        limit: ${LIMIT_PAGE},
        page: ${page},
        sort: {
          field: "${options[selectedIndex].field}"
          order: ${options[selectedIndex].order}
        }
        filter: {
          categoriesIdIn: [${id}]
        }
      ) {
        metadata {
          count
          pages
        }
        collection {
          title
          quality
          price
          id
          discount
          reserved
          location
          vatRate
          address
          pricesForEachUnitEnabled
          images {
            filename
            url
          }
        }
      }

      categories (
        topOnly: true
      ) {
        id
        title
        productsCount
        subCategories {
          id
          title
          productsCount
        }
      }
    }
  `);

  useEffect(() => {
    setQuery(`
      query {
        products (
          limit: ${LIMIT_PAGE},
          page: ${page},
          sort: {
            field: "${options[selectedIndex].field}"
            order: ${options[selectedIndex].order}
          }
          filter: {
            categoriesIdIn: [${id}]
          }
        ) {
          metadata {
            count
            pages
          }
          collection {
            title
            quality
            price
            id
            discount
            reserved
            location
            vatRate
            address
            pricesForEachUnitEnabled
            images {
              filename
              url
            }
          }
        }
  
        categories (
          topOnly: true
        ) {
          id
          title
          productsCount
          subCategories {
            id
            title
            productsCount
          }
        }
      }
    `)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex, page, id])

  const [data, setData] = useState({})

  useEffect(() => {
    fetch(`${process.env.GATSBY_API_URL_SHOP}/graphql`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authentication": `${process.env.GATSBY_TOKEN_SHOP}`,
      }, body: JSON.stringify({
        query
      })
    }).then(response => {
      return response.json();
    }).then(data => {
      setData(data.data);
      setLoader(false);
    })
  }, [query])

  const onChangeView = view => {
    setViewPage(view)
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    setPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const finElem = () => {
    if (data && data.categories && data.categories.length > 0) {
      let arr = [];

      data.categories.forEach(category => {
        if (category.id === id) {
          arr.push(category);
        }

        if (category.subCategories.length) {
          if (findIndex(category.subCategories, ['id', id]) !== -1) {
            arr.push(category);
            arr.push(category.subCategories[findIndex(category.subCategories, ['id', id])]);
          }
        }
      })

      return arr
    }
  }

  if (loader) {
    return (
      <noindex>
        <Box sx={{ minHeight: '600px', display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', flexDirection: 'column' }}>
          <Skeleton animation="wave" width={'160px'} />

          <Typography sx={{ fontSize: '20px', marginTop: '30px', }}>Загрузка</Typography>
        </Box>
      </noindex>
    )
  }

  return (
    <PageBox>
      <CatalogSectionContainer sx={{ marginTop: 0, marginBottom: '20px', }}>
        <Breadcrumbs category={finElem()} />
      </CatalogSectionContainer>

      <SEO pageData={{ title: 'Каталог оборудования' }} />

      <CatalogSectionContainer sx={{ marginTop: { xs: '24px', sm: '24px', lg: 0, } }} title={"Каталог оборудования"}>
        <Box sx={style.wrapperPage}>
          <Box sx={style.sideBar}>
            <CatalogSectionMenu data={data?.categories} />
            <CatalogSectionAd type={'vertical'} />
          </Box>

          <Box sx={style.content}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '26px', flexWrap: { sm: 'wrap', lg: 'nowrap' }, }}>
              <Box sx={{ display: 'flex' }}>
                <List
                  component="nav"
                  sx={{ padding: 0, }}
                >
                  <ListItem
                    aria-controls="lock-menu"
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="listbox"
                    aria-label="Сортировать"
                    button
                    id="lock-button"
                    onClick={handleClickListItem}
                    sx={{ padding: 0, }}
                  >
                    <ListItemText primary="Сортировать:" sx={style.titleSorting} />
                    <ListItemText primary={options[selectedIndex].title} sx={style.valueSorting} />
                    <ListItemText primary={<KeyboardArrowDownIcon sx={{ color: '#B6BBC2' }} />} sx={{ height: '22px', }} />
                  </ListItem>
                </List>

                <Menu
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox"
                  }}
                  anchorEl={anchorEl}
                  id="lock-menu"
                  onClose={handleClose}
                  open={open}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.title}
                      onClick={(event) => handleMenuItemClick(event, index, option)}
                      selected={index === selectedIndex}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Box sx={style.wrapperChangeView}>
                <Box className={viewPage === 'table' ? 'active' : null} onClick={onChangeView.bind(this, 'table')} sx={style.wrapperPseudoButton}>
                  <Box sx={style.buttonTable}></Box>
                  <Box sx={style.buttonTable}></Box>
                </Box>

                <Box className={viewPage === 'block' ? 'active' : null} onClick={onChangeView.bind(this, 'block')} sx={style.wrapperPseudoButton}>
                  <Box sx={style.buttonCard}></Box>
                  <Box sx={style.buttonCard}></Box>
                  <Box sx={style.buttonCard}></Box>
                  <Box sx={style.buttonCard}></Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', }}>
              <CatalogSectionProduct data={data?.products} view={viewPage} />

              {data?.products?.collection?.length && (
                <Box sx={style.pagin}>
                  <Pagination count={data?.products?.metadata?.pages} onChange={changePage} page={page} />
                </Box>
              )}
            </Box>
          </Box>

          <noindex>
            <CatalogSectionAd type={'vertical'} />
          </noindex>
        </Box>
      </CatalogSectionContainer>
    </PageBox>
  );
}